import BACKEND_CLIENT from './api.js';
import React, { useEffect, useState } from 'react';
import FileUploadArea from './FileUploadArea';
import useVideoRecording from './useVideoRecording';
import Loader from './Loader';
import { toast } from 'react-toastify';
import { uploadToS3, fetchPresignedUrl } from './uploadHelpers';
import UpgradePlanButton from './components/plans/UpgradePlanButton'
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import { MIN_LOADING } from './constants.js';
import './Upload.css';
import "react-datepicker/dist/react-datepicker.css";

const Upload = () => {
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [tags, setTags] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [shouldHighlightTitle, setShouldHighlightTitle] = useState(false);
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [shouldHighlightDate, setShouldHighlightDate] = useState(false);
  const [accountCanUpload, setAccountCanUpload] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const {
    isRecording, recordedVideo, videoRef,
    handleStartRecording, handleStopRecording, objectUrlRef
  } = useVideoRecording();
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const isVideoFile = (file) => {
    return file.type.startsWith('video/');
  };

  const handleDrop = (e) => {
    e.preventDefault();
  };

  const handleFileChange = (file) => {
    if(!file) {
      setFile(null);
    } else if (file && isVideoFile(file)) {
      setFile(file);
    } else {
      toast.error('Please upload a video file.');
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleAddTag = () => {
    const newTag = prompt('Enter a new tag:');
    if (newTag) {
      setTags([...tags, newTag]);
    }
  };

  useEffect(() => {
    const fetchAccountLimits = async () => {
      try {
        const requestPromise = BACKEND_CLIENT.get('/v1/plan/allowed');
        // Note, only doing this so it doesn't stutter so quickly and shows what users would actually see
        // Need to set a timeout before throwing an exception
        const res = await Promise.all([requestPromise, new Promise(resolve => setTimeout(resolve, MIN_LOADING))])
        const response = res[0]
        setAccountCanUpload(response.data.accountCanUpload);
        setIsLoading(false);
      } catch (error) {
        toast.error(error.response?.data?.message || 'Network Error. Please refresh the page.');
      }
    };

    fetchAccountLimits();
  }, []);

  const handleUpload = async () => {
    if (!file) return; // Check if there is a file to upload
    const validTitle = !title || title.trim() === '';
    setShouldHighlightTitle(validTitle);
    var hasInvalidInput = validTitle;

    setShouldHighlightDate(!startDate);
    hasInvalidInput = hasInvalidInput || !startDate;

    if (hasInvalidInput) {
      return;
    }

    setIsUploading(true);
    const month = startDate.getMonth() + 1;
    const year = startDate.getFullYear();
    const day = startDate.getDate();
    const date = `${year}-${month}-${day}`;

    var data;
    try {
      data = await fetchPresignedUrl(title, date, file.name, file.type);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Network Error');
      setIsUploading(false);
      return;
    }

    try {
      const uploadResponse = await uploadToS3(data.presignedData, file);
    } catch (error) {
      toast.error("Failed uploading file. Ensure the file is actually a proper video.")
      setIsUploading(false);
      return;
    }

    try {
      const response = await BACKEND_CLIENT.get(`/v1/entry/${data.metadata.entryId}/upload/complete`)
    } catch (error) {
      toast.error(error.response?.data?.message || 'Network Error');
    }
    setIsUploading(false);
    navigate("/journals");
  }

  const handleRecordedVideoUpload = async () => {
    if (isUploading || !recordedVideo) return; // Check if already uploading or no recorded video

    const validTitle = !title || title.trim() === '';
    setShouldHighlightTitle(validTitle);
    var hasInvalidInput = validTitle;

    setShouldHighlightDate(!startDate);
    hasInvalidInput = hasInvalidInput || !startDate;

    if (hasInvalidInput) {
      return;
    }

    setIsUploading(true);
    const month = startDate.getMonth() + 1;
    const year = startDate.getFullYear();
    const day = startDate.getDate();
    const date = `${year}-${month}-${day}`;

    try {
      // Fetching the presigned URL from your server
      const data = await fetchPresignedUrl(title, date, "browser_uploaded.mp4", "video/mp4");
      const uploadResponse = await uploadToS3(data.presignedData, recordedVideo);
      try {
        const response = await BACKEND_CLIENT.get(`/v1/entry/${data.metadata.entryId}/upload/complete`)
      } catch (error) {
        toast.error(error.response?.data?.message || 'Network Error');
      }
      navigate("/journals");
    } catch (error) {
      toast.error(error.response?.data?.message || 'Network Error');
    }
    setIsUploading(false);
  };

  var body = (
    <p>Uploading...</p>
  )

  if (isLoading) {
    body = (
      <>
        <Loader />
      </>
    )
  } else if (!accountCanUpload) {
    body = (
      <>
        <p>You have reached your plan's quota.</p>
        <UpgradePlanButton modalOpenByDefault={true} />
      </>
    )
  } else if (!isUploading && accountCanUpload) {
    body = (
      <>
        <div className="upload-title">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            maxDate={new Date()}
            placeholderText="Select a date in the past"
            className={(shouldHighlightDate) ? 'required-input' : ''}
            strictParsing
          />
          <input
            type="text"
            placeholder="Enter Journal Title"
            value={title}
            onChange={handleTitleChange}
            className={(shouldHighlightTitle) ? 'required-input' : ''}
          />
        </div>
        <div className="video-recording">
          <video ref={videoRef} style={{ display: !isRecording ? 'none' : 'block' }} className={isRecording ? 'mirrored' : ''}></video>
          {recordedVideo && !isRecording && (
            <>
              <video controls src={objectUrlRef}></video>
            </>
          )}
          {recordedVideo && !isRecording && accountCanUpload && (
            <>
              <button onClick={handleRecordedVideoUpload}>Upload Video</button>
            </>
          )}
          {isRecording && (
            <button onClick={handleStopRecording}>Stop Recording</button>
          )}
          {!isRecording && !recordedVideo && (
            <button onClick={handleStartRecording}>Start Recording</button>
          )}
          {!isRecording && recordedVideo && (
            <button onClick={handleStartRecording}>Start New Recording</button>
          )}
        </div>
        {!isRecording && !recordedVideo && <FileUploadArea onFileChange={handleFileChange} file={file} />}
        {!isUploading && file && accountCanUpload && (
          <button onClick={handleUpload}>Upload Video</button>
        )}
      </>
    )
  }

  return (
    <div className="upload-container" onDragOver={handleDragOver} onDrop={handleDrop}>
      {body}
    </div>
  );
};

export default Upload;
