import BACKEND_CLIENT from './api.js';
import React, { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader';
import 'react-toastify/dist/ReactToastify.css';
import './VerifyEmail.css';
import { useAuth } from './components/auth/AuthProvider';

const VerifyEmail = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [didResend, setDidResend] = useState(false);
  const [didVerify, setDidVerify] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setIsAuthenticated } = useAuth();

  useEffect(() => {
    const handleVerification = async () => {
      if (!searchParams.get('uid') | !searchParams.get('token')) return;
      setIsLoading(true);
      const uid = searchParams.get('uid');
      const token = searchParams.get('token');
      try {
        const response = await BACKEND_CLIENT.post(`/v1/verify_email/${uid}/${token}`);
        setDidVerify(response.data["success"]);
        setIsLoading(false);
      } catch (error) {
        toast.error(error.response?.data?.message || 'Network Error');
        setIsLoading(false);
      }
    };

    handleVerification();

  }, []);

  const handleResendVerification = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await BACKEND_CLIENT.post('/v1/resend_verification_email');
      setDidResend(true);
      setIsLoading(false);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Network Error');
      setIsLoading(false);
    }
  };

  var body = (
    <>
      <p>An email has been sent out to your email with a link to reset the password.</p>
    </>
  )

  if (isLoading) {
    body = (
      <>
      <Loader />
      </>
    )
  }

  if (!isLoading & !didVerify & !didResend) {
    body = (
      <>
        <p>Please check your email for a verification code or click the following button to resend.</p>
        <form onSubmit={handleResendVerification}>
          <button type="submit">Resend Verification</button>
        </form>
      </>
    );
  }

  if (didVerify) {
    setIsAuthenticated(true);
    body = (
      <>
        <p>Your account is now verified!</p>
      </>
    )
  }

  return (
    <div className="verify-email">
      {body}
    </div>
  )
};

export default VerifyEmail;
