import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileTab from './ProfileTab';
import VideoSettingsTab from './VideoSettingsTab';
import BillingTab from './BillingTab';
import TagsTab from './TagsTab';
import PlanTab from './PlanTab';
import './Settings.css';

const Settings = () => {
  const [activeTab, setActiveTab] = useState('profile');
  const navigate = useNavigate();

  return (
    <div className="settings-container">
      <div className="sidebar">
        <button className={activeTab === 'profile' ? 'selected-tab' : 'tab'} onClick={() => setActiveTab('profile')}>Profile</button>
        <button className={activeTab === 'video-settings' ? 'selected-tab' : 'tab'} onClick={() => setActiveTab('video-settings')}>Video Settings</button>
        <button className={activeTab === 'plan' ? 'selected-tab' : 'tab'} onClick={() => setActiveTab('plan')}>Plan Details</button>
        <button className={activeTab === 'billing' ? 'selected-tab' : 'tab'} onClick={() => setActiveTab('billing')}>Billing + Invoicing</button>
        <button className={activeTab === 'tags' ? 'selected-tab' : 'tab'} onClick={() => setActiveTab('tags')}>Tags</button>
        <button className='logout-tab' onClick={() => navigate("/logout")}>Logout</button>
      </div>
      <div className="content">
        {activeTab === 'profile' && <ProfileTab />}
        {activeTab === 'video-settings' && <VideoSettingsTab />}
        {activeTab === 'plan' && <PlanTab />}
        {activeTab === 'billing' && <BillingTab />}
        {activeTab === 'tags' && <TagsTab />}
      </div>
    </div>
  );
};

export default Settings;
