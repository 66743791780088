import BACKEND_CLIENT from './api.js';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader'; // Import the Loader component
import { MIN_LOADING } from './constants.js';

const BillingTab = () => {

  const redirectToCustomerPortal = async () => {
    try {
      const response = await BACKEND_CLIENT.get('/v1/customer/portal');
      window.location.href = response.data.customerPortalUrl;
    } catch (error) {
      console.error('Error redirecting to Stripe:', error);
      toast.error(error.response?.data?.message || 'Network Error');
    }
  };

  return (
    <div>
      <h2>Billing + Invoicing</h2>
      <button onClick={redirectToCustomerPortal}>Billing + Invoicing Portal</button>
    </div>
  );
};

export default BillingTab;
