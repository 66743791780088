import BACKEND_CLIENT from './api.js';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ResetPasswordAction.css';

const ResetPasswordAction = () => {
  const { uidb64, token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [didReset, setDidReset] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await BACKEND_CLIENT.post(`/v1/reset/${uidb64}/${token}`, { newPassword: newPassword });
      setDidReset(true);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Network Error');
    }
  };

  var body = (
    <>
      <p>Your password has been reset.</p>
    </>
  )

  if (!didReset) {
    body = (
      <form onSubmit={handleResetPassword}>
        <input
          type="password"
          name="newPassword"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)} 
          placeholder="New Password"
          required
        />
        <button type="submit">Submit</button>
      </form>
    )
  }
  return (
    <div className="reset-container">
      {body}
    </div>
  );
};

export default ResetPasswordAction;
