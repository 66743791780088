import BACKEND_CLIENT from './api.js';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader'; // Import the Loader component
import { MIN_LOADING } from './constants.js';
import Chip from './Chip';
import { SketchPicker } from 'react-color';
import ColorSelector from './ColorSelector'
import EditableStringField from './components/common/EditableStringField'
import './TagsTab.css';
import './TagRowDetails.css';

const TagRowDetails = ({ id, nameProp, setNameProp, colorProp, shouldSync, setColorProp, canDelete, deleteElement }) => {
  return (
    <>
      <td>
        <Chip
          name={nameProp}
          color={colorProp}
          shouldHandleClick={false}
        />
      </td>
      <td>
        <EditableStringField
          id={id}
          value={nameProp}
          onChange={setNameProp}
          onBlur={() => void(0)}
          name={'name'}
        />
      </td>
      <td>
        <ColorSelector
          colorState={colorProp}
          setColorState={(color) => setColorProp({id: id, color: color})}
          onChangeComplete={(color) => setColorProp({id: id, color: color})}
        />
      </td>
      { canDelete && (
        <td>
        <span title="Remove" className="remove-tag" onClick={() => deleteElement({id})}>x</span>
        </td>
      )}
    </>
  );
};

export default TagRowDetails;
