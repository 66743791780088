import BACKEND_CLIENT from './api.js';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ResetPassword.css';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [didReset, setDidReset] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await BACKEND_CLIENT.post('/v1/reset_password', { email: email });
      setDidReset(true);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Network Error');
    }
  };

  var body = (
    <>
      <p>An email has been sent out to {email} with a link to reset the password.</p>
    </>
  )

  if (!didReset) {
    body = (
      <form onSubmit={handleResetPassword}>
        <input
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <button type="submit">Reset Password</button>
      </form>
    )
  }
  return (
    <div className="reset-container">
      {body}
    </div>
  );
};

export default ResetPassword;
