import React, { useState, useEffect } from 'react';
import './Chip.css';

const Chip = ({ id, name, color, handleClick, shouldHandleClick }) => {
  return (
    <span className="chip" style={{backgroundColor: color}}>
      <span className={shouldHandleClick ? "chip-token" : "chip-tokenn-no-click"} onClick={() => shouldHandleClick ? handleClick(id) : void(0)}>{name}</span>
    </span>
  );
}

export default Chip;