import axios from 'axios';
import { refreshAccessToken } from './auth'; // Import the refreshAccessToken function

const JOTYSSEY_DOMAIN = process.env.REACT_APP_JOTYSSEY_BACKEND_ENDPOINT;

const BACKEND_CLIENT = axios.create({
  baseURL: JOTYSSEY_DOMAIN,
});

BACKEND_CLIENT.defaults.withCredentials = true;

BACKEND_CLIENT.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry && originalRequest.baseURL.includes(JOTYSSEY_DOMAIN) && !originalRequest.url.includes('token/refresh') && !originalRequest.url.includes('verify_email')) {
      originalRequest._retry = true;
      try {
        await refreshAccessToken(); // Function to refresh token
        return axios(originalRequest);
      } catch (refreshError) {
        // Redirect to login if refresh token is also expired or invalid
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }
    console.log("Rejecting")
    return Promise.reject(error);
  }
);

export default BACKEND_CLIENT;
export {JOTYSSEY_DOMAIN};