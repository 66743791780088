import React, { useState } from 'react';
import './TranscriptionEditor.css'; // Make sure to create this CSS file
import EditPencil from '../../assets/edit.png'
import SaveDisk from '../../assets/save.png'

const TranscriptionEditor = ({ transcription, handleWordClick, updateTranscription, saveTranscription }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [didEdit, setDidEdit] = useState(false);
  const isMobile = typeof window.navigator !== "undefined" && /(android|iphone|ipad|ipod|opera mini|iemobile|webos)/i.test(window.navigator.userAgent);
  var additionalChar = 1;
  if (isMobile) {
    additionalChar = 2;
  }

  const handleActualEdit = (e, index) => {
    updateTranscription(index, e.target.value)
    setDidEdit(true);
  }

  const handleSave = async () => {
    setIsEditing(false);
    if (!didEdit) return;
    setDidEdit(false);
    saveTranscription()
    // Actually update transcript in backend?? Or when done editing??
  };

  return (
    <>
      <div className="transcription-header">
        <h4>Transcription</h4>
        {isEditing && <img src={SaveDisk} alt="Save" style={{ width: '20px', height: '20px', cursor: 'pointer', marginLeft: '10px'}} onClick={handleSave} />}
        {!isEditing && <img src={EditPencil} alt="Edit" style={{ width: '20px', height: '20px', cursor: 'pointer', marginLeft: '10px'}} onClick={() => setIsEditing(true)} />}
      </div>
      <div className="transcription-body">
        {transcription.words.map((item, index) => {
          return (
          isEditing ? (
            <span className="editable-word" key={index}>
              <input
                type="text"
                value={item.word}
                onChange={(e) => handleActualEdit(e, index)}
                style={{ width: `${item.word.length + additionalChar}ch` }}
                autoFocus={index === -1}
              />
            </span>
          ) : ( item.word.trim() && (
            <span
              key={index}
              className="transcript-word"
              onClick={() => handleWordClick(item.start_time)}
            >
              {item.word}
            </span>
          ))
        )})}
      </div>
    </>
  );
};

export default TranscriptionEditor;