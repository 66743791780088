import React, { useState, useEffect } from 'react';
import './EditableField.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditableDateField = ({ value, onChange, onBlur, name, id }) => {
    const [isEditing, setIsEditing] = useState(false);
    const getMMDDYYYY = (dateObj) => {
      return ('0' + (dateObj.getMonth()+1)).slice(-2) + '/' + ('0' + dateObj.getDate()).slice(-2) + '/' + dateObj.getFullYear();
    }
    return isEditing ? (
      <DatePicker
        selected={value}
        onChange={(date) => onChange(date)}
        maxDate={new Date()}
        onBlur={() => {
          setIsEditing(false);
          onBlur();
        }}
        placeholderText="Select a date in the past"
        className="editable-datebox"
        wrapperClassName="editable-textbox"
        autoFocus
        strictParsing
      />
    ) : (
      <span className="editable" onClick={() => setIsEditing(true)}>
        {getMMDDYYYY(value)}
      </span>
    );
}

export default EditableDateField;