import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BACKEND_CLIENT from './api.js'
import { useAuth } from './components/auth/AuthProvider';

const Logout = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();

  useEffect(() => {
    const logoutUser = async () => {
      try {
        await BACKEND_CLIENT.get('v1/logout');
        setIsAuthenticated(false);
        navigate('/login');
      } catch (error) {
        console.error('Error logging out:', error);
        // Handle error (optional)
      }
    };

    logoutUser();
  }, [navigate]);

  return <div>Logging out...</div>; // Or any other indicator you prefer
};

export default Logout;