import React, { useState } from 'react';
import Modal from 'react-modal';
import PlanSelection from './PlanSelection';
import './PlanSelection.css';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const UpgradePlanButton = ({ modalOpenByDefault }) => {
  const [modalIsOpen, setModalIsOpen] = useState(modalOpenByDefault === true);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <button onClick={openModal}>Upgrade Plan</button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Plan Selection Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <button onClick={closeModal} className="modal-close-button">&times;</button>
        <PlanSelection currentPlanId="basic" />
      </Modal>
    </div>
  );
};

export default UpgradePlanButton;
