import React, { useState, useEffect } from 'react';
import './Dropdown.css';

const Dropdown = ({ allTags, handleCreateProp, handleSelectProp, handleInputProp, includeAdd }) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredTags, setFilteredTags] = useState(allTags);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isTextboxSelected, setIsTextboxSelected] = useState(false);

  useEffect(() => {
    if (inputValue.trim()) {
      const matchedTags = allTags.filter(tag => 
        tag.name.toLowerCase().includes(inputValue.trim().toLowerCase())
      );
      setFilteredTags(matchedTags);
      setIsDropdownVisible(true);
    } else {
      if (filteredTags.length !== allTags.length) {
        setFilteredTags(allTags);
      } 
      setIsDropdownVisible(false);
    }
  }, [inputValue, allTags, filteredTags]);

  const handleInputBlur = () => {
    // Delay hiding the dropdown to allow click event on dropdown items to fire
    setTimeout(() => {
      setIsTextboxSelected(false);
      setIsDropdownVisible(false);
    }, 200); // Adjust the delay as needed
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    handleInputProp('');
  };

  const handleSelect = (tagId) => {
    // Perform action on tag selection
    handleSelectProp(tagId);
    setIsDropdownVisible(false);
    if (includeAdd){
      setInputValue('');
    } else {
      setInputValue(allTags.find((obj) => obj.id === tagId).name);
    }
    setIsTextboxSelected(false)
  };

  const handleCreate = async () => {
    if (!includeAdd) return;

    try {
      // Reset input and close dropdown
      handleCreateProp(inputValue);
      setInputValue('');
      setIsDropdownVisible(false);
      setIsTextboxSelected(false)
    } catch (error) {
      console.error('Error creating tag:', error);
    }
  };

  return (
    <div className="tag-dropdown">
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={() => setIsTextboxSelected(true)}
        onBlur={handleInputBlur}
        placeholder={includeAdd ? "Search or create tag" : "Search tag"}
      />
      {(isDropdownVisible || isTextboxSelected) && (
        <ul className="dropdown-menu-mine">
          {filteredTags.map((tag, index) => (
            <li key={index} onClick={() => handleSelect(tag.id)} tabIndex="0">
              {tag.name}
            </li>
          ))}
          {includeAdd && inputValue && (filteredTags.length === 0 || filteredTags.find((elem) => elem.name !== inputValue.trim())) && (
            <li onClick={handleCreate} className="create-tag-option">
              Create "{inputValue}"
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;