
const SECONDS_TO_SUFFIX = [
  [24 * 60 * 60, "Day(s)"],
  [60 * 60, "Hour(s)"],
  [60, "Minute(s)"],
  [1, "Second(s)"]
];

export const calculateTimeStr = (numberOfSeconds) => {
  if (!numberOfSeconds || numberOfSeconds === 0) return "0 Seconds";

  for (let [size, suffix] of SECONDS_TO_SUFFIX) {
    let convertedTime = numberOfSeconds / size;
    if (convertedTime >= 1 && convertedTime < 1000) {
      return `${convertedTime.toFixed(0)} ${suffix}`;
    }
  }
  return `${numberOfSeconds / SECONDS_TO_SUFFIX[0][0].toFixed(2)} ${SECONDS_TO_SUFFIX[0][1]}`;
};
