import BACKEND_CLIENT from './api.js';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import './MyJournals.css';
import Loader from './Loader'; // Import the Loader component
import { MIN_LOADING } from './constants.js';

const MyJournals = () => {
  const [journalEntries, setJournalEntries] = useState([]);
  const [displayJournals, setDisplayJournals] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [journalsPerPage] = useState(6); // Number of entries per page
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchJournalEntries = async () => {
      try {
        /*
          Expected schema
          [{
            id: 1,
            title: 'Reflections on a Rainy Day',
            thumbnail: 'https://i.ytimg.com/vi/OFtOr5VACgA/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAVffpN2NUBhPMDG6GAHt60renJgw',
            // Note: Change this so sort actually works
            date: 'April 5, 2024'
          },
          ...]
        */
        const requestPromise = BACKEND_CLIENT.get('/v1/entries');
        // Note, only doing this so it doesn't stutter so quickly and shows what users would actually see
        // Need to set a timeout before throwing an exception
        const res = await Promise.all([requestPromise, new Promise(resolve => setTimeout(resolve, MIN_LOADING))])
        const response = res[0]

        const sortedJournals = [...response.data].sort((a, b) => {
          if (a.date.year > b.date.year) return -1;
          if (a.date.year < b.date.year) return 1;
          if (a.date.month > b.date.month) return -1;
          if (a.date.month < b.date.month) return 1;
          if (a.date.day > b.date.day) return -1;
          if (a.date.day < b.date.day) return 1;
          if (a.createdAt > b.createdAt) return -1;
          if (a.createdAt < b.createdAt) return 1;
          return 0;
        });
        setJournalEntries(sortedJournals); // Assuming the response has the data in the expected format
        const startIndex = (currentPage - 1) * journalsPerPage;
        const endIndex = startIndex + journalsPerPage;
        setDisplayJournals(sortedJournals.slice(startIndex, endIndex));
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching journal entries:', error);
        toast.error(error.response?.data?.message || 'Network Error');
        // Handle the error appropriately. Use a toast or sad cloud or something
      }
    };

    fetchJournalEntries();
  }, []);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    const startIndex = (pageNumber - 1) * journalsPerPage;
    const endIndex = startIndex + journalsPerPage;
    setDisplayJournals(journalEntries.slice(startIndex, endIndex));
  };

  // Calculate the total number of pages
  const pageCount = Math.ceil(journalEntries.length / journalsPerPage);

    var body = (
    <Loader />
  )

  if (!isLoading) {
    // Note: Paginate via a cursor-based pagination or something similar.
    body = (
      <div className="my-journals">
        <h1>My Journal Entries</h1>
        <div className="sorting-options">
        </div>
        <div className="journal-gallery">
          {displayJournals.map((entry, index) => (
            <div key={index} className="journal-card">
              <Link to={`/entry/${entry.id}/${encodeURIComponent(entry.title)}`}>
                {entry.thumbnail && (
                  <img src={entry.thumbnail} alt={entry.title} />
                )}
                {!entry.thumbnail && (<div>Loading...</div>)}
                <div className="journal-info">
                  <h3>{entry.title}</h3>
                  <p>{entry.date.year}-{entry.date.month}-{entry.date.day}</p>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <div className="pagination">
          {[...Array(pageCount).keys()].map(number => (
            <button key={number} onClick={() => handlePageClick(number + 1)}>
              {number + 1}
            </button>
          ))}
        </div>
      </div>
    );
  }
  return (
    <>
      {body}
    </>
  )
};

export default MyJournals;
