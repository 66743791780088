import BACKEND_CLIENT from './api.js';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader'; // Import the Loader component
import { MIN_LOADING } from './constants.js';
import Chip from './Chip';
import { SketchPicker } from 'react-color';
import ColorSelector from './ColorSelector';
import TagRowDetails from './TagRowDetails';
import './TagsTab.css';

const TagsTab = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tags, setTags] = useState([]);
  const [newTagName, setNewTagName] = useState('');
  const [newTagColor, setNewTagColor] = useState('#FFFFFF'); // default color
  const [shouldSync, setShouldSync] = useState(false);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const requestPromise = BACKEND_CLIENT.get('/v1/category');
        // Note, only doing this so it doesn't stutter so quickly and shows what users would actually see
        // Need to set a timeout before throwing an exception
        const res = await Promise.all([requestPromise, new Promise(resolve => setTimeout(resolve, MIN_LOADING))])
        const response = res[0]
        var categories = response.data.categories.map((elem) => ({
          id: elem.id,
          name: elem.name,
          color: elem.color,
          shouldSync: false
        })).sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        })
        setTags(categories);
        setIsLoading(false);
        setShouldSync(false)
      } catch (error) {
        toast.error(error.response?.data?.message || 'Network Error');
      }
    };

    fetchTags();
  }, []);

  const handleAddTag = async () => {
    if (!newTagName || newTagName.trim() === '') return;

    try {
      const response = await BACKEND_CLIENT.post("/v1/category", {
        category_name: newTagName,
        category_color: newTagColor
      })
      const newCategory = {
        id: response.data.id,
        name: response.data.name,
        color: response.data.color,
        shouldSync: false
      }
      setTags([...tags, newCategory].sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      }))
      setNewTagName('');
      setNewTagColor('#FFFFFF');
    } catch (error) {
      toast.error(error.response?.data?.message || 'Network Error');
    }

  };

  const handleSync = async () => {
    if (!shouldSync) return;
    try {
      const response = await BACKEND_CLIENT.post("/v1/bulk/category", {
        categories: tags.map((elem) => ({
          id: elem.id,
          color: elem.color,
          name: elem.name
        }))
      })
      setTags(response.data.map((elem) => ({
        id: elem.id,
        color: elem.color,
        name: elem.name,
        shouldSync: false
      })).sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      }))
      setShouldSync(false);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Network Error');
    }
  }

  const handleColorChange = (color) => {
    setNewTagColor(color.hex);
  };

  const handleTagsTitleChange = async ({id, value}) => {
    console.log(`Received ${id} and ${value}`)
    const newTags = tags.map((obj) => {
      if (obj.id === id) {
        return { ...obj, name: value, shouldSync: true};
      }
      return obj;
    })
    setTags(newTags)
    setShouldSync(true)
  }

  const handleTagsColorChange = async ({id, color}) => {
    if (!color || color.trim() === '' || color.length !== 7) return;
    const newTags = tags.map((obj) => {
      if (obj.id === id) {
        return { ...obj, color: color, shouldSync: true};
      }
      return obj;
    })
    setTags(newTags)
    setShouldSync(true)
  }

  const deleteTag = async ({id}) => {
    var tempShouldSync = false;
    const newTags = tags.filter((obj) => {
      if (obj.id === id) {
        return false;
      }
      tempShouldSync = tempShouldSync || obj.shouldSync;
      return obj.id !== id;
    });

    try {
      const response = await BACKEND_CLIENT.delete(`/v1/category/${id}`)
      setTags(newTags);
      setShouldSync(tempShouldSync);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Network Error')
    }
  }

  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    )
  }

  return (
    <div>
      <div className="new-tag">
        <input
          type="text"
          placeholder="Tag name..."
          value={newTagName}
          onChange={(e) => setNewTagName(e.target.value)}
        />
        <ColorSelector
          colorState={newTagColor}
          setColorState={setNewTagColor}
          onChangeComplete={handleColorChange}
        />
      </div>
      <div>
        <button onClick={handleAddTag}>Add Tag</button>
        {shouldSync && (
            <button onClick={handleSync}>Sync</button>
        )}
      </div>
      <table>
        <thead>
          <tr>
            <th>Look</th>
            <th>Title</th>
            <th>Background Color</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tags.map((entry, index) => (
            <tr key={entry.id} className={entry.shouldSync ? "tag-row-sync" : ""}>
              <TagRowDetails
                key={entry.id}
                id={entry.id}
                nameProp={entry.name}
                setNameProp={handleTagsTitleChange}
                colorProp={entry.color}
                setColorProp={handleTagsColorChange}
                canDelete={true}
                deleteElement={deleteTag}
              />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TagsTab;
