import React, { useState, useEffect } from 'react';
import './EditableField.css';

const EditableStringField = ({ value, onChange, onBlur, name, id }) => {
    const [isEditing, setIsEditing] = useState(false);
    return isEditing ? (
      <input
        type="text"
        value={value}
        onChange={(e) => onChange({ id: id, value: e.target.value})}
        onBlur={() => {
          setIsEditing(false);
          onBlur({name: name, value: value, id: id});
        }}
        autoFocus
        className="editable-textbox"
        style={{ width: `${value.length + 1}ch` }}
      />
    ) : (
      <span className="editable" onClick={() => setIsEditing(true)}>
        {value}
      </span>
    );
}

export default EditableStringField;