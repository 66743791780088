import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import Upload from './Upload';
import JournalEntry from './JournalEntry';
import MyJournals from './MyJournals';
import SearchPage from './SearchPage';
import Settings from './Settings';
import Login from './Login';
import ResetPassword from './ResetPassword';
import ResetPasswordAction from './ResetPasswordAction';
import VerifyEmail from './VerifyEmail';
import VerifyEmailAction from './VerifyEmailAction';
import { ToastContainer } from 'react-toastify';
import Logout from './Logout';
import Signup from './Signup';
import './App.css';
import Navbar from './Navbar';
import { AuthProvider } from './components/auth/AuthProvider';
import './api.js';

const AppContent = () => {
  return (
    <>
      <Navbar />
      <div className="top-body">
          <Routes>
            <Route path="/" element={
                <Dashboard />
            } />
            <Route path="/upload" element={
                <Upload />
            } />
            <Route path="/entry/:id" element={
                <JournalEntry />
            } />
            <Route path="/entry/:id/:title" element={
                <JournalEntry />
            } />
            <Route path="/journals" element={
                <MyJournals />
            } />
            <Route path="/search" element={
                <SearchPage />
            } />
            <Route path="/settings" element={
                <Settings />
            } />
            <Route path="/logout" element={<Logout />} />
            <Route path="/login" element={<Login />} />
            <Route path="/reset_password" element={<ResetPassword />} />
            <Route path="/reset/:uidb64/:token/" element={<ResetPasswordAction />} />
            <Route path="/verify_email" element={<VerifyEmail />} />
            <Route path="/verify_email/:uidb64/:token/" element={<VerifyEmailAction />} />
            <Route path="/signup" element={<Signup />} />
          </Routes>
      </div>
    </>
  )
};

export default AppContent;
