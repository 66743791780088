const FileUploadArea = ({ onFileChange, file }) => {
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    onFileChange(e.dataTransfer.files[0]);
  };

  return (
    <div className="file-drop-area" onDragOver={handleDragOver} onDrop={handleDrop}>
      {file ? (
        <div className="file-info">
          <p>{file.name}</p>
          <button onClick={() => onFileChange(null)}>Remove File</button>
        </div>
      ) : (
        <input type="file" onChange={(e) => onFileChange(e.target.files[0])} />
      )}
    </div>
  );
};

export default FileUploadArea;
