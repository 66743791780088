import React, { createContext, useContext, useState, useEffect } from 'react';
import BACKEND_CLIENT from '../../api.js';
import { useLocation, useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthChecker = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, setIsAuthenticated } = useAuth();

  useEffect(() => {
    const check_auth_status = async () => {
      if (location.pathname === '/logout' ||
          location.pathname === '/login' ||
          location.pathname === '/reset_password' ||
          location.pathname.includes('/reset/') ||
          location.pathname === '/verify_email' ||
          location.pathname === '/signup'
      ) {
        return;
      }

      try {
        const response = await BACKEND_CLIENT.get('/v1/check_account_status');
        setIsAuthenticated(response.data.accountStatus === "VERIFIED");
        if (response.data.accountStatus === "NOT_VERIFIED") {
          window.location.href = "/verify_email";
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    check_auth_status();
  }, [location, isAuthenticated]); // Dependency array now includes location

  return <>{children}</>;
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <AuthContext.Provider value={{ isAuthenticated, isAuthenticated, setIsAuthenticated }}>
      <AuthChecker>{children}</AuthChecker>
    </AuthContext.Provider>
  );
};
