import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import $ from 'jquery';
import Popper from 'popper.js';
import Dropdown from 'react-bootstrap/Dropdown';
import { BsThreeDots } from "react-icons/bs";
import './MeatballMenu.css';

const MeatballMenu = ({ onRemove }) => {
  return (
    <Dropdown autoClose={true}>
      <Dropdown.Toggle >
        <BsThreeDots />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item className="remove-item" onClick={onRemove}>Delete</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MeatballMenu;