
export const startRecording = async (videoRef, setIsRecording, mediaRecorderRef, setRecordedVideo, setObjectUrlRef) => {
	const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
	videoRef.current.srcObject = stream;
	videoRef.current.muted = true;
	videoRef.current.play();
	setIsRecording(true);

	mediaRecorderRef.current = new MediaRecorder(stream);
	const chunks = [];
	mediaRecorderRef.current.ondataavailable = (e) => chunks.push(e.data);
	mediaRecorderRef.current.onstop = () => {
	  const completeBlob = new Blob(chunks, { type: 'video/mp4' });
	  const reader = new FileReader();

	  reader.onload = () => {
	    const videoURL = reader.result;
	    videoRef.current.src = videoURL;
	    videoRef.current.load(); // Load the video to process metadata

	    videoRef.current.onloadedmetadata = () => {
	      // Video metadata is now loaded
	      setRecordedVideo(completeBlob);
	      setObjectUrlRef(URL.createObjectURL(completeBlob))
	      videoRef.current.muted = false; // Enable audio for playback
	    };
	  };

	  reader.readAsDataURL(completeBlob);
	};

	mediaRecorderRef.current.start();
};

export const stopRecording = (mediaRecorderRef, videoRef, setIsRecording) => {
	mediaRecorderRef.current.stop();

	// Stop each track on the media stream
	videoRef.current.srcObject.getTracks().forEach(track => track.stop());

	videoRef.current.srcObject = null;
	setIsRecording(false);
};