import BACKEND_CLIENT from './api.js';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loader from './Loader'; // Import the Loader component
import './Dashboard.css';
import { MIN_LOADING } from './constants.js';

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [entries, setEntries] = useState([]);
  const [stats, setStats] = useState({
    totalEntries: 0,
    recentTopic: ''
  });

  useEffect(() => {
    const fetchDashboardDetails = async () => {
      try {
        /*
          Expected schema
          [{
            id: 1,
            title: 'Reflections on a Rainy Day',
            thumbnail: 'https://i.ytimg.com/vi/OFtOr5VACgA/hqdefault.jpg?sqp=-oaymwEcCOADEI4CSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAVffpN2NUBhPMDG6GAHt60renJgw',
            // Note: Change this so sort actually works
            date: 'April 5, 2024'
          },
          ...]
        */
        const requestPromise = BACKEND_CLIENT.get('/v1/dashboard_details');
        // Note, only doing this so it doesn't stutter so quickly and shows what users would actually see
        // Need to set a timeout before throwing an exception
        const res = await Promise.all([requestPromise, new Promise(resolve => setTimeout(resolve, MIN_LOADING))])
        const response = res[0]
        setEntries(response.data.entries); // Assuming the response has the data in the expected format
        setStats(response.data.stats)
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching dashboard details:', error);
        // Handle the error appropriately. Use a toast or sad cloud or something
      }
    };

    fetchDashboardDetails();
  }, []);

  const header = (
    <>
      <h1>Welcome to Your Video Journal</h1>
      <p className="summary">
        Here's a quick look at your recent entries and journal stats.
      </p>
    </>
  )

  var body = (
    <Loader />
  )

  if (!isLoading) {
    body = (
    <>
      {header}
      <div className="quick-stats">
        <p>Total Entries: {stats.totalEntries}</p>
        {/*<p>Recent Topic: {stats.recentTopic}</p>*/}
      </div>
      <div className="recent-entries">
        {entries.map((entry) => (
          <Link key={entry.id} to={`/entry/${entry.id}/${encodeURIComponent(entry.title)}`} className="entry-card">
            <img src={entry.thumbnail} alt={`Thumbnail for ${entry.title}`} />
            <div className="entry-info">
              <h3>{entry.title}</h3>
              <p>{entry.date}</p>
            </div>
          </Link>
        ))}
      </div>
    </>
  )
  }
  return (
    <div className="dashboard">
      {body}
    </div>
  );
};

export default Dashboard;
