import { useState, useRef } from 'react';
import { startRecording, stopRecording } from './videoRecordingHelpers';

const useVideoRecording = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [objectUrlRef, setObjectUrlRef] = useState(null);

  const handleStartRecording = async () => {
    await startRecording(videoRef, setIsRecording, mediaRecorderRef, setRecordedVideo, setObjectUrlRef);
  };

  const handleStopRecording = () => {
    stopRecording(mediaRecorderRef, videoRef, setIsRecording);
  };

  return { isRecording, recordedVideo, videoRef, handleStartRecording, handleStopRecording, objectUrlRef };
};

export default useVideoRecording;
