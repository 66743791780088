import BACKEND_CLIENT from './api.js';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader'; // Import the Loader component
import { MIN_LOADING } from './constants.js';

const VideoSettingsTab = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [videoSettings, setVideoSettings] = useState({
    transcriptionLanguage: '',
    compressionType: '',
  });

  const handleInputChange = (e) => {
    setVideoSettings({ ...videoSettings, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetchVideoSettings = async () => {
      try {
        const requestPromise = BACKEND_CLIENT.get('/v1/video_settings');
        // Note, only doing this so it doesn't stutter so quickly and shows what users would actually see
        // Need to set a timeout before throwing an exception
        const res = await Promise.all([requestPromise, new Promise(resolve => setTimeout(resolve, MIN_LOADING))])
        const response = res[0]
        setVideoSettings({transcriptionLanguage: response.data.transcriptionLanguage, compressionType: response.data.compressionType});
        setIsLoading(false);
      } catch (error) {
        toast.error(error.response?.data?.message || 'Network Error');
      }
    };

    fetchVideoSettings();
  }, []);

  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    )
  }

  return (
    <div>
      <h2>Video Settings</h2>
      <p>
        Transcription Language: {videoSettings.transcriptionLanguage}
      </p>
      <p>
        Compression Type: {videoSettings.compressionType}
      </p>
    </div>
  );
};

export default VideoSettingsTab;
