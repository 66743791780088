import BACKEND_CLIENT from './api.js';

export const uploadToS3 = async (presignedResponse, file) => {
  const formData = new FormData();

  // Add the presigned fields to the form data
  Object.keys(presignedResponse.fields).forEach(key => {
    formData.append(key, presignedResponse.fields[key]);
  });

  // Append the file to the form data
  formData.append('file', file);

  // Perform the POST request to the presigned URL
  const response = await fetch(presignedResponse.url, {
    method: 'POST',
    body: formData,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  return response;
};

export const fetchPresignedUrl = async (title, date, fileName, contentType) => {
  const response = await BACKEND_CLIENT.get(`/v1/presigned_url?title=${encodeURIComponent(title)}&journal_date=${date}&file_name=${fileName}&content_type=${contentType}`);
  return response.data;
};