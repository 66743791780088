import React, { useState } from 'react';
import BACKEND_CLIENT from './api.js';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Login.css';

const Login = () => {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await BACKEND_CLIENT.post('/v1/login', credentials);
      navigate('/'); // Redirect to Dashboard
    } catch (error) {
      toast.error(error.response?.data?.message || 'Network Error');
    }
  };

  const handlePasswordReset = () => {
    // Navigate to password reset page or handle reset logic
    navigate('/reset_password'); // Example reset password route
  };

  const handleSignup = () => {
    // Navigate to password reset page or handle reset logic
    navigate('/signup'); // Example reset password route
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin}>
        <input
          type="email"
          name="email"
          value={credentials.email}
          onChange={handleChange}
          placeholder="Email"
          required
        />
        <input
          type="password"
          name="password"
          value={credentials.password}
          onChange={handleChange}
          placeholder="Password"
          required
        />
        <button type="submit">Login</button>
        <button type="button" onClick={handlePasswordReset}>Reset Password</button>
        <button type="button" onClick={handleSignup}>Signup</button>
      </form>
    </div>
  );
};

export default Login;
