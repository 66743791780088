import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './components/auth/AuthProvider';
import './Navbar.css';

const Navbar = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const [shouldDisplaySearch, setShouldDisplaySearch] = useState(true);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const handleLocation = async () => {
      const isSearch = location.pathname === '/search';
      if (isSearch) {
        setSearchTerm('');
      }
      setShouldDisplaySearch(!isSearch);
    };
    handleLocation();
  }, [location]);

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/search?query=${encodeURIComponent(searchTerm)}`);
  };

  return (
    <nav className="navbar">
      <Link to="/" className="nav-logo">KaraJournal</Link>
      {isAuthenticated ? (
        <div className="nav-links">
          <Link to="/upload">Upload</Link>
          <Link to="/journals">Journal</Link>
          <Link to="/settings">Settings</Link>
        </div>
      ) : (
        <div className="login-nav-links">
          <Link to="/login">Login</Link>
          <Link to="/signup">Signup</Link>
        </div>
      )}
      {shouldDisplaySearch && isAuthenticated && <form className="search-form" onSubmit={handleSearch}>
        <input
          type="text"
          placeholder="Search journals..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button type="submit">Search</button>
      </form>}
      {!shouldDisplaySearch && isAuthenticated && <form className="search-form" />}
    </nav>
  );
};

export default Navbar;
