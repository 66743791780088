import BACKEND_CLIENT from './api.js';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader'; // Import the Loader component
import { MIN_LOADING } from './constants.js';

const ProfileTab = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const handleInputChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await BACKEND_CLIENT.post('/v1/profile_details', { firstName: userInfo.firstName, lastName: userInfo.lastName });
    } catch (error) {
      toast.error(error.response?.data?.message || 'Network Error');
    }
  };

  useEffect(() => {
    const fetchProfileDetails = async () => {
      try {
        const requestPromise = BACKEND_CLIENT.get('/v1/profile_details');
        // Note, only doing this so it doesn't stutter so quickly and shows what users would actually see
        // Need to set a timeout before throwing an exception
        const res = await Promise.all([requestPromise, new Promise(resolve => setTimeout(resolve, MIN_LOADING))])
        const response = res[0]
        setUserInfo({firstName: response.data.first_name, lastName: response.data.last_name, email: response.data.email});
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching profile details:', error);
        toast.error(error.response?.data?.message || 'Network Error');
      }
    };

    fetchProfileDetails();
  }, []);

  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    )
  }

  return (
    <div>
      <h2>Profile</h2>
      <form onSubmit={handleProfileUpdate}>
        <label>
          Email: {userInfo.email}
        </label>
        <label>
          First Name:
          <input type="text" name="firstName" value={userInfo.firstName} onChange={handleInputChange} />
        </label>
        <label>
          Last Name:
          <input type="text" name="lastName" value={userInfo.lastName} onChange={handleInputChange} />
        </label>
        <button type="submit">Update Profile</button>
      </form>
    </div>
  );
};

export default ProfileTab;
