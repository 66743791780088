
const BYTES_TO_SUFFIX = [
  [1_000_000_000_000_000, "PB"],
  [1_000_000_000_000, "TB"],
  [1_000_000_000, "GB"],
  [1_000_000, "MB"],
  [1000, "KB"],
  [1, "B"] // Added to handle bytes
];

export const calculateSizeStr = (numberOfBytes) => {
  if (!numberOfBytes || numberOfBytes === 0) return "0B";

  for (let [size, suffix] of BYTES_TO_SUFFIX) {
    let convertedSize = numberOfBytes / size;
    if (convertedSize >= 1 && convertedSize < 1000) {
      return `${convertedSize.toFixed(2)}${suffix}`;
    }
  }
  return `${numberOfBytes / BYTES_TO_SUFFIX[0][0].toFixed(2)}${BYTES_TO_SUFFIX[0][1]}`;
};
