import BACKEND_CLIENT from './api.js';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Loader from './Loader'; // Import the Loader component
import { MIN_LOADING } from './constants.js';
import { calculateSizeStr } from './common/sizeUtils'
import { calculateTimeStr } from './common/timeUtils'
import UpgradePlanButton from './components/plans/UpgradePlanButton'

const PlanTab = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [planDetails, setPlanDetails] = useState({
    currentPlan: '',
  });

  useEffect(() => {
    const fetchPlanDetails = async () => {
      try {
        const requestPromise = BACKEND_CLIENT.get('/v1/plan_details');
        // Note, only doing this so it doesn't stutter so quickly and shows what users would actually see
        // Need to set a timeout before throwing an exception
        const res = await Promise.all([requestPromise, new Promise(resolve => setTimeout(resolve, MIN_LOADING))])
        const response = res[0]
        setPlanDetails({
          currentPlan: response.data.currentPlan,
          storageLimit: response.data.storageLimit,
          videoCountLimit: response.data.videoCountLimit,
          timeLimit: response.data.timeLimit,
          storageUse: response.data.storageUse,
          timeUse: response.data.timeUse,
          videoCount: response.data.videoCount,
        });
        setIsLoading(false);
      } catch (error) {
        toast.error(error.response?.data?.message || 'Network Error');
      }
    };

    fetchPlanDetails();
  }, []);

  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    )
  }

  return (
    <>
      <div>
        <h2>Plan</h2>
        <p>Current Plan: {planDetails.currentPlan}</p>
        <p>Storage Limit: {calculateSizeStr(planDetails.storageUse)} / {calculateSizeStr(planDetails.storageLimit)}</p>
        <p># Of Videos: {planDetails.videoCount} / {planDetails.videoCountLimit}</p>
        <p>Duration Limit: {calculateTimeStr(planDetails.timeUse)} / {calculateTimeStr(planDetails.timeLimit)}</p>
      </div>
      <UpgradePlanButton modalOpenByDefault={false} />
    </>
  );
};

export default PlanTab;
