import BACKEND_CLIENT from './api.js';
import React, { useEffect, useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import Dropdown from './Dropdown';
import Chip from './Chip';
import './JournalEntry.css';
import Loader from './Loader';
import { MIN_LOADING } from './constants.js';
import EditableDateField from './components/common/EditableDateField'
import EditableStringField from './components/common/EditableStringField'
import MeatballMenu from './components/common/MeatballMenu'
import TranscriptionEditor from './components/transcript/TranscriptionEditor'

const JournalEntry = () => {
  const { id } = useParams(); // Assuming 'id' is part of the route parameter
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [videoUrl, setVideoUrl] = useState([]);
  const [transcription, setTranscription] = useState({});
  const [listOfNotes, setListOfNotes] = useState([]);
  const [notes, setNotes] = useState("");
  const [isEditingDate, setIsEditingDate] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [title, setTitle] = useState("");
  const [originalTitle, setOriginalTitle] = useState("");
  const [date, setDate] = useState({});
  const [originalDate, setOriginalDate] = useState({});
  const [tags, setTags] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [videoStatus, setVideoStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedValue, setSelectedValue] = useState("");
  const [maxLength, setMaxLength] = useState(300);
  const [dateError, setDateError] = useState(false);
  const [startDate, setStartDate] = useState(null);

  useEffect(() => {
    const handleVideoEntryData = async () => {
      try {
        const entryRequestPromise = BACKEND_CLIENT.get(`/v1/entry/${id}`);
        const categoryRequestPromise = BACKEND_CLIENT.get('/v1/category');
        const notesRequestPromise = BACKEND_CLIENT.get(`/v1/entry/${id}/notes`);
        // Note, only doing this so it doesn't stutter so quickly and shows what users would actually see
        // Need to set a timeout before throwing an exception
        const res = await Promise.all([categoryRequestPromise, entryRequestPromise, notesRequestPromise, new Promise(resolve => setTimeout(resolve, MIN_LOADING))])
        const entryResponse = res[1]
        setVideoUrl(entryResponse.data.videoUrl);
        setTranscription(entryResponse.data.transcription);
        setNotes(entryResponse.data.notes);
        const sortedTags = entryResponse.data.tags.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        })
        setTags(sortedTags);
        setTitle(entryResponse.data.title);
        setOriginalTitle(entryResponse.data.title)
        var d = {day: entryResponse.data.date.day, month: entryResponse.data.date.month, year: entryResponse.data.date.year}
        setDate(d);
        setStartDate(new Date(d.year, d.month - 1, d.day))
        setOriginalDate(d)
        setVideoStatus(entryResponse.data.videoStatus);

        const categoryResponse = res[0];
        const ids = entryResponse.data.tags.map((obj) => obj.id)
        const allTagsInterim = categoryResponse.data.categories.filter(val => !ids.includes(val.id))
        const sortedAllTags = allTagsInterim.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        })
        setAllTags(sortedAllTags);

        const notesResponse = res[2];
        const allNotes = notesResponse.data.map((obj) => {
          return {
            id: obj.id,
            text: obj.text,
            createdAt: obj.createdAt
          }
        })
        const sortedNotes = allNotes.sort((a, b) => {
          if (a.createdAt > b.createdAt) return -1;
          if (a.createdAt < b.createdAt) return 1;
          return 0;
        })
        setListOfNotes(sortedNotes)
        setIsLoading(false);
      } catch (error) {
        console.log(error)
        toast.error(error.response?.data?.message || 'Network Error');
      }
    };

    handleVideoEntryData();
  }, []);

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const handleAddTag = (tag) => {
    if (tag && !tags.includes(tag)) {
      setTags([...tags, tag]);
    }
  };

  const handleSave = async () => {
    // Code to save the edited transcription, notes, and tags to the backend
    if (!notes.trim()) return;

    try {
      const response = await BACKEND_CLIENT.post(`/v1/entry/${id}/notes`, {text: notes});
      setListOfNotes([{id: response.data.id, text: response.data.text, createdAt: response.data.createdAt}, ...listOfNotes]);
      setNotes('');
    } catch(error) {
      toast.error(error.response?.data?.message || 'Network Error')
    }
  };

  const handleWordClick = (timestamp) => {
    if (videoRef.current) {
      videoRef.current.currentTime = timestamp;
      videoRef.current.play();
    }
  };

  const handleTagDropdownChange = async (selectedTagId) => {
    // Make a post request and remove from list of valid tags set to empty
    const newTags = [...tags, ...allTags.filter((availableTag) => availableTag.id === selectedTagId)]
    const newAllTags = allTags.filter((availableTag) => availableTag.id !== selectedTagId).sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
    try {
      const response = await BACKEND_CLIENT.post(`/v1/entry/${id}/categories`, {categories: newTags})
      setTags(newTags)
      setAllTags(newAllTags)
    } catch(error) {
      toast.error(error.response?.data?.message || 'Network Error')
    }
  };

  const handleCreateNewTag = async (tagName) => {
    try {
      const response = await BACKEND_CLIENT.post("/v1/category", {category_name: tagName})
      const newTags = [...tags, response.data]
      await BACKEND_CLIENT.post(`/v1/entry/${id}/categories`, {categories: newTags})
      setTags(newTags)
    } catch(error) {
      toast.error(error.response?.data?.message || 'Network Error')
    }
  }

  const handleRemoveTag = async (category_id) => {
    // Make a post request and remove from list of valid tags set to empty
    const newAllTags = [...allTags, ...tags.filter((availableTag) => availableTag.id === category_id)].sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    })
    const newTags = tags.filter((availableTag) => availableTag.id !== category_id)
    try {
      const response = await BACKEND_CLIENT.post(`/v1/entry/${id}/categories`, {categories: newTags})
    } catch(error) {
      toast.error(error.response?.data?.message || 'Network Error')
    }
    setTags(newTags)
    setAllTags(newAllTags)
  }

  const updateBackend = (type, value) => {
    const data = { [type]: value };
    BACKEND_CLIENT.post(`/v1/entry/${id}`, data)
      .then(response => {
        console.log('Update successful:', response);
      })
      .catch(error => {
        console.error('Error updating:', error);
        toast.error(error.response?.data?.message || 'Network Error');
      });
  };

  const dateValidation = (dateInput) => {
    console.log(dateInput)
    if (!dateInput) return;
    setDate({day: dateInput.getDate(), month: dateInput.getMonth() + 1, year: dateInput.getFullYear()})
    setStartDate(new Date(dateInput.getFullYear(), dateInput.getMonth(), dateInput.getDate()))
  }

  const dateUpdateBackend = () => {
    if (isNaN(startDate)) {
      setDateError(true);
      toast.error("Invalid date. Must be in MM/DD/YYYY format.");
      setDate(originalDate);
      setStartDate(originalDate.year, originalDate.month - 1, originalDate.day)
      return
    }

    if (originalDate === date) return;

    let currDate = new Date()
    if (currDate.getTime() < startDate || currDate.getFullYear() - startDate.getFullYear() > 100) {
      toast.error("Invalid date. Must be in the past 100 years.");
      setDate(originalDate);
      setStartDate(originalDate.year, originalDate.month - 1, originalDate.day)
      return;
    }
    updateBackend("date", `${date.year}-${date.month}-${date.day}`)
    setOriginalDate(date)
  }

  const titleUpdateBackend = ({name, value}) => {
    if (!value.trim()) {
      toast.error("Must have a title.");
      setTitle(originalTitle)
      return;
    }

    if (value.trim() === originalTitle.trim()) return;

    updateBackend(name, value);
    setOriginalTitle(value);
  }

  const updateTranscription = (index, word) => {
    const updatedWords = transcription.words.map((obj, idx) => {
      if (idx === index) {
        return {...obj, word: word};
      }
      return obj;
    });

    setTranscription({ ...transcription, words: updatedWords});
  }

  const removeJournalEntry = async () => {
    try {
      await BACKEND_CLIENT.post(`/v1/entry/${id}/remove`);
      navigate("/journals");
    } catch (error) {
      toast.error(error.response?.data?.message || 'Network Error');
    }
  }

  const saveTranscription = async () => {
    try {
      const data = {
        transcription: {
          words: transcription.words.map((obj) => ({
            word: obj.word,
            startTime: obj.start_time,
            endTime: obj.end_time
          }))
        }
      }

      await BACKEND_CLIENT.post(`/v1/entry/${id}/transcription`, data);

    } catch (error) {
      toast.error(error.response?.data?.message || 'Network Error');
    }
  }

  var body = <></>;
  const videoHeader = (
    <>
      <h2>
        <span className="video-header-with-dropdown">
          <span className="video-header-fields">
            <EditableDateField
              key={originalDate}
              value={startDate}
              onChange={dateValidation}
              onBlur={dateUpdateBackend}
              name={'date'}
            />
            :<span style={{ marginLeft: `10px` }}>
              <EditableStringField
                key={originalTitle}
                value={title}
                onChange={({ value }) => setTitle(value)}
                onBlur={titleUpdateBackend}
                name={'title'}
              />
            </span>
          </span>
          <MeatballMenu
            onRemove={removeJournalEntry}
          />
        </span>
      </h2>
    </>
  )

  if (isLoading) {
    body = (
      <>
        <Loader />
      </>
    )
  } else if (videoStatus === "processing") {
    body = (
      <>
        {videoHeader}
        <h5>Processing your recent upload. Please wait momentarily.</h5>
      </>
    )
  } else if (videoStatus === "completed") {
    var transcriptionBody = transcription.status !== "completed" ? (
      <>
        <h4>Transcription</h4>
        <p>Transcription is still processing... Try again in a few moments</p>
      </>
    ) : (
      <TranscriptionEditor
        transcription={transcription}
        handleWordClick={handleWordClick}
        updateTranscription={updateTranscription}
        saveTranscription={saveTranscription}
      />
    )


    body = (
      <>
        {videoHeader}
        <video ref={videoRef} controls src={videoUrl} className="video-player"></video>
        <div className="tags">
          <div>
          {tags.map((tag, index) => (
            <Chip
              key={index}
              id={tag.id}
              color={tag.color}
              shouldHandleClick={true}
              handleClick={handleRemoveTag}
              name={tag.name}
            />
          ))}
          </div>
          <Dropdown
            key={allTags}
            handleCreateProp={handleCreateNewTag}
            handleSelectProp={handleTagDropdownChange}
            handleInputProp={() => void(0)}
            allTags={allTags}
            includeAdd={true}
          />
        </div>
        <div className="transcription">
          {transcriptionBody}
        </div>
        <div>
          {listOfNotes && (
            <div className="feed">
              <h4>Notes</h4>
              {listOfNotes.map((post, index) => (
                <span key={index} className="feedPost">{post.text}</span>
              ))}
            </div>
          )}
          <textarea
            className="notes-input"
            placeholder="Additional notes"
            value={notes}
            onChange={handleNotesChange}
          ></textarea>
          <div className="save-changes">
            <button onClick={handleSave}>Post Note</button>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="journal-entry">
      {body}
    </div>
  );
};

export default JournalEntry;

