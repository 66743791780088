import BACKEND_CLIENT from './api.js';
import React, { useEffect, useState } from 'react';
import './SearchPage.css';
import { Link, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Dropdown from './Dropdown'
import { MIN_LOADING } from './constants.js';

const SearchPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(searchParams.get('query') || '');
  const [categories, setCategories] = useState([]);
  const [filters, setFilters] = useState({ dateRange: '', tags: '' });
  const [searchResults, setSearchResults] = useState([]);
  const [allTags, setAllTags] = useState([]);

  const handleSearchResults = async (query, tags) => {
    if (!query && !tags) return;
    try {
      const response = await BACKEND_CLIENT.get(`/v1/search?query=${query || ''}&categories=${tags}`)
      setSearchResults(response.data)
    } catch (error) {
      toast.error(error.response?.data?.message || 'Network Error');
    }
  };

  useEffect(() => {
    // Update the search term when searchParams change
    setSearchTerm(searchParams.get('query') || '');
    handleSearchResults(searchParams.get('query') || '', filters.tags);

  }, [searchParams]);

  useEffect(() => {
    const handleSearchResultsInner = async () => {
      try {
        var searchPromise = Promise.resolve();
        if (searchTerm) {
          searchPromise = BACKEND_CLIENT.get(`/v1/search?query=${searchTerm}&categories=${filters.tags}`);
        }

        const categoryRequestPromise = BACKEND_CLIENT.get('/v1/category');
        // Note, only doing this so it doesn't stutter so quickly and shows what users would actually see
        // Need to set a timeout before throwing an exception
        const res = await Promise.all([categoryRequestPromise, searchPromise, new Promise(resolve => setTimeout(resolve, MIN_LOADING))])

        if (searchTerm) {
          const searchResponse = res[1];
          setSearchResults(searchResponse.data);
        }

        const categoryResponse = res[0];
        const sortedAllTags = categoryResponse.data.categories.sort((a, b) => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        })
        setAllTags(sortedAllTags);

      } catch (error) {
        toast.error(error.response?.data?.message || 'Network Error');
      }
    };

    handleSearchResultsInner();
  }, []);

  const handleTagSelect = async (tagId) => {
    setFilters({ ...filters, tags: tagId });
    handleSearchResults(searchParams.get('query') || '', tagId);
  }

  const handleSearch = async (e) => {
    e.preventDefault();
    setSearchParams({ query: searchTerm });
    handleSearchResults(searchTerm, filters.tags);
  };

  const handleFilterChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  return (
    <div className="search-page">
      <form onSubmit={handleSearch}>
        <input
          type="text"
          placeholder="Search journals..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button type="submit">Search</button>
      </form>
      <div className="filters">
        <input
          type="date"
          name="dateRange"
          value={filters.dateRange}
          onChange={handleFilterChange}
        />
        <Dropdown
          key={allTags}
          includeAdd={false}
          handleSelectProp={handleTagSelect}
          allTags={allTags}
          handleInputProp={(input) => setFilters({ ...filters, tags: '' })}
        />
      </div>
      <div className="results">
        {searchResults.map((result) => (
          <Link key={result.id} to={`/entry/${result.id}/${encodeURIComponent(result.title)}`}>
          <div className="result-item">
              <img src={result.thumbnail} alt={result.title} />
              <div className="result-info">
                <h3>{result.title}</h3>
                <p>{result.date}</p>
                {/* Tags or other metadata can be added here */}
              </div>
          </div>
        </Link>
        ))}
      </div>
    </div>
  );
};

export default SearchPage;
