import BACKEND_CLIENT from './api.js';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './VerifyEmailAction.css';

const VerifyEmailAction = () => {
  const { uidb64, token } = useParams();
  const [didReset, setDidReset] = useState(false);

  useEffect(() => {
    const handleVerifyEmailAction = async () => {
      try {
        const response = await BACKEND_CLIENT.post(`/v1/verify_email/${uidb64}/${token}`);
        setDidReset(true);
      } catch (error) {
        toast.error(error.response?.data?.message || 'Network Error');
      }
    };

    handleVerifyEmailAction();
  }, []);

  var body = (
    <>
      <p>You are verified!</p>
    </>
  )

  if (!didReset) {
    body = (
      <p>Trying to verify...</p>
    )
  }
  return (
    <div className="verify-email">
      {body}
    </div>
  );
};

export default VerifyEmailAction;
