import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from './Dashboard';
import Upload from './Upload';
import JournalEntry from './JournalEntry';
import MyJournals from './MyJournals';
import SearchPage from './SearchPage';
import Settings from './Settings';
import Login from './Login';
import ResetPassword from './ResetPassword';
import ResetPasswordAction from './ResetPasswordAction';
import VerifyEmail from './VerifyEmail';
import VerifyEmailAction from './VerifyEmailAction';
import { ToastContainer } from 'react-toastify';
import Logout from './Logout';
import Signup from './Signup';
import './App.css';
import Navbar from './Navbar';
import AppContent from './AppContent';
import { AuthProvider } from './components/auth/AuthProvider';
import './api.js';


function App() {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
      <div>
        <ToastContainer position="bottom-center" />
      </div>
    </Router>
  );
}

export default App;
