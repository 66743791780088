import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../../Loader'; // Import the Loader component
import { MIN_LOADING } from '../../constants.js';
import BACKEND_CLIENT from '../../api.js';
import './PlanSelection.css';
import { BsInfoCircle } from "react-icons/bs";

const PlanSelection = ( { currentPlanId }) => {
  const plans = [
    {
      backendId: 'Free',
      id: 'free',
      title: 'Free',
      description: 'You free',
      price: '$0/month',
      features:['SMS and MMS add-on', '150,000 Monthly Email Sends', 'Unlimited Users', 'Unlimited Audiences', 'Phone & Priority Support', 'Pre-built Email Templates', '300+ Integrations'],
      buttonText: 'Buy Now',
      buttonClass: ''
    },
    {
      backendId: 'Basic',
      id: 'basic',
      title: 'Basic',
      description: 'You aight',
      price: '$5/month',
      features: ['Texintg'],
      buttonText: 'Buy Now',
      buttonClass: ''
    },
    {
      backendId: 'Standard',
      id: 'standard',
      title: 'Standard',
      description: 'You ballin',
      price: '$10/month',
      features: ['SMS and MMS add-on', '150,000 Monthly Email Sends', 'Unlimited Users', 'Unlimited Audiences', 'Phone & Priority Support', 'Pre-built Email Templates', '300+ Integrations'],
      buttonText: 'Buy Now',
      buttonClass: ''
    },
    // {
    //   backendId: 'Premium',
    //   id: 'premium',
    //   title: 'Premium',
    //   description: 'You dummy rich',
    //   price: '???/month',
    //   features: ['SMS and MMS add-on', '150,000 Monthly Email Sends', 'Unlimited Users', 'Unlimited Audiences', 'Phone & Priority Support', 'Pre-built Email Templates', '300+ Integrations'],
    //   buttonText: 'Buy Now',
    //   buttonClass: ''
    // },
  ];

  const [isLoading, setIsLoading] = useState(true);
  const [plansMetadata, setPlansMetadata] = useState([]);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const requestPromise = BACKEND_CLIENT.get('/v1/plans');
        // Note, only doing this so it doesn't stutter so quickly and shows what users would actually see
        // Need to set a timeout before throwing an exception
        const res = await Promise.all([requestPromise, new Promise(resolve => setTimeout(resolve, MIN_LOADING))])
        const response = res[0]
        setPlansMetadata(response.data.plans.map((planDetails) => ({
          isCurrentPlan: planDetails.isCurrentPlan,
          canDowngrade: planDetails.canDowngrade,
          isLowerTier: planDetails.isLowerTier,
          planType: planDetails.planType,
          id: planDetails.id,
          cantDowngradeReason: planDetails.cantDowngradeReason,
        })));
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching plan details', error);
        // Handle the error appropriately. Use a toast or sad cloud or something
      }
    };

    fetchPlans();
  }, []);

  const redirectToCheckout = async (planId) => {
    try {
      const response = await BACKEND_CLIENT.post('/v1/checkout/session', { planId });
      window.location.href = response.data.checkoutUrl;
    } catch (error) {
      console.error('Error redirecting to Stripe:', error);
      toast.error(error.response?.data?.message || 'Network Error');
    }
  };

  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    )
  }

  return (
    <section className="plan-selection">
      {plans.map(plan => {
        const metadata = plansMetadata.find((obj) => obj.planType === plan.backendId) || {}
        var purchaseButton = (<></>)
        if (metadata.isCurrentPlan) {
          purchaseButton = (<button className="currentPlan">Current Plan</button>)
        } else if (!metadata.isLowerTier) {
          purchaseButton = (
            <button onClick={() => redirectToCheckout(metadata.id)} className={plan.buttonClass}>Buy Now</button>
          )
        } else if (metadata.canDowngrade) {
          purchaseButton = (
            <button onClick={() => redirectToCheckout(metadata.id)} className={plan.buttonClass}>Downgrade</button>
          )
        } else {
          purchaseButton = ( 
            <>
              <button className="currentPlan" >Unavailable</button>
              <BsInfoCircle style={{ width: '20px', height: '20px', cursor: 'pointer', marginLeft: '10px'}} alt={metadata.cantDowngradeReason} title={metadata.cantDowngradeReason}/>
            </>
          )
        }
        return (
        <div key={plan.id} className={`plan ${plan.id}`}>
          <div className="plan-header">
            <h2>{plan.title}</h2>
            <p>{plan.description}</p>
            <p className="plan-price">{plan.price}</p>
            {purchaseButton}
          </div>
          <ul className="plan-features">
            {plan.features.map((feature, index) => (
              <li key={index} className={feature.includes('✓') ? 'included' : ''}>
                {feature}
              </li>
            ))}
          </ul>
        </div>
      )}
    )}
    </section>
  );
};

export default PlanSelection;